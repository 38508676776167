.leaflet-control-dialog {
  position: absolute;
  background-color: #fff;
  padding: 0px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.leaflet-control-dialog .leaflet-control-dialog-inner {
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 100%;
  height: 100%;
  padding: 20px 0px;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-grabber {
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0px;
  padding: 3px;
  font-size: 15px;
  line-height: 15px;
  color: #ccc;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-grabber:hover {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-close {
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
  padding: 2px;
  font-size: 16px;
  line-height: 16px;
  color: #666;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-close:hover {
  cursor: pointer;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-contents {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0 20px;
  min-height: 50px;
  overflow: auto;
  box-sizing: border-box;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-resizer {
  position: absolute;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  bottom: 0px;
  right: 0px;
  padding: 2px;
  font-size: 16px;
  line-height: 16px;
  color: #ccc;
}

.leaflet-control-dialog
  .leaflet-control-dialog-inner
  .leaflet-control-dialog-resizer:hover {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
